import { API } from "../../config/api";
import { lng2num } from "../converter";

export const GetSubLevelFilterResources = async (lng: string, serviceFamily: string, serviceName: string, productName: string) => {
    return new Promise<any>(async (resolve, reject) => {
        try {
            const response = await fetch(API.GetSubLevelFilterResources, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({
                    language: lng2num(lng),
                    serviceFamily,
                    serviceName,
                    productName
                }), // body data type must match "Content-Type" header
            });
            const data = await response.json();
            resolve(data);
        } catch (error) {
            reject(error);
        }
    })
}