// more info see https://nceretailpricetest.chinacloudsites.cn/swagger/index.html

const host = process.env.REACT_APP_API;
const base = `${host}/RetailPrice`;

export const API = {
    GetProductLevelFilterResources: `${base}/GetProductLevelFilterResources`,
    GetSubLevelFilterResources: `${base}/GetSubLevelFilterResources`,
    QueryRetailPrice: `${base}/QueryRetailPrice`,
    GetLatestPriceListFile: `${base}/GetLatestPriceListFile`,
    GetDefaultPriceListTableData: `${base}/GetDefaultPriceListTableData`
}