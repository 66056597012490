const zh = {
    title: "Azure 定价",
    subtitle: "灵活的购买和定价选项，适用于各种云方案",
    p1: "√按实际使用量计费  ",
    p2: "√阶梯计费  ",
    p3: "√预览模式的服务优惠计费"
}
const en = {
    title: "Azure Pricing",
    subtitle: "Flexible purchase and pricing options for a variety of cloud solutions",
    p1: "√Billed by actual usage  ",
    p2: "√Tiered billing  ",
    p3: "√Discount for preview services"
}
export {
    en, zh
}