import { getServiceFamilyOptionsForDropdown } from ".";
import { Option } from "../../types";
import { firstLoadUseGetDefaultPriceListTableDataAPI } from "../../config/firstload";
import { getDefaultValueForDropdown } from "../api";

export const onLanguageChange = (
    reset: () => void,
    lng: string,
    setServiceFamilyOptions: (arr: Option[]) => void,
    setServiceFamily: (text: string) => void,
    setLocation: (text: string) => void,
    setType: (text: string) => void,
    RePull: () => void
) => {
    reset();
    //获得Tree形数据
    getServiceFamilyOptionsForDropdown(lng, setServiceFamilyOptions, () => {
        //如果不使用默认首屏接口，就用默认参数进行搜索
        if (!firstLoadUseGetDefaultPriceListTableDataAPI) {
            getDefaultValueForDropdown(lng).then(({ ServiceFamily, Location, Type }) => {
                setServiceFamily(ServiceFamily);
                setTimeout(() => {
                    setLocation(Location);
                    setType(Type);
                    RePull();
                }, 500);
            }).catch();
        } else {
            RePull();
        }
    });

}