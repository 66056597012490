export const zh = {
    serviceFamily: "服务所属的类别",
    location: "部署资源的Azure数据中心",
    term: "预留或节省计划的期限，可选择一年或三年",
    meterName: "用于衡量服务的使用情况",
    unitOfMeasure: "用于衡量服务的使用情况",
    type: "资源消耗类型，包含：<br />" +
        "Reservation：预留, Azure 预留项通过承诺多种产品的一年期或三年期计划，帮助你节省资金。<br />" +
        "SavingsPlanConsumption：节省计划的消耗，通过承诺节省计划获取针对你所用资源的折扣，最高为每小时承诺金额。<br />" +
        "Consumption：即用即付。"
}

export const en = {
    serviceFamily: "Category of the service",
    location: "Azure data center where the resource is deployed",
    term: "Term length for an Azure savings plan and Reservation, including 1 year or 3 years.",
    meterName: "Name of the meter",
    unitOfMeasure: "How usage is measured for the service.",
    type: "<b>Meter consumption type. Types are:</b><br />" +
        "<b>Reservation:</b>Azure Reservations help you save money by committing to one-year or three-year plans for multiple products. <br />" +
        "<b>Saving plan: </b>Committing to a savings plan allows you to get discounts, up to the hourly commitment amount, on the resources you use. <br />" +
        "<b>Consumption: </b>Pay as you go."
}