import { Popover } from "@fluentliteui/popover";
import "@fluentliteui/popover/dist/index.css";
import { useTranslation } from "react-i18next";

import { MakePopover } from "../functions/gen/makePopover";

export const TableHead = () => {
    const { t } = useTranslation();
    return [
        {
            title: <MakePopover width={150} content={t('tips.serviceFamily')} title={t('serviceFamily')} />,
            key: 'serviceFamily'
        },
        {
            title: t('serviceName'), key: 'serviceName'
        },
        { title: t('productName'), key: 'productName' },
        {
            title: MakePopover({
                width: 250,
                title: t('location'),
                content: t('tips.location')
            }),
            key: 'location'
        },
        {
            title: t('skuName'),
            key: 'skuName'
        },
        {
            title: MakePopover({
                width: 350,
                isHtml: true,
                title: t('type'),
                content: t('tips.type')
            }),
            key: 'type'
        },
        {
            title: MakePopover({
                width: 250,
                title: t('term'),
                content: t('tips.term'),
            }),
            key: 'term'
        },
        {
            title: MakePopover({
                width: 200,
                title: t('meterName'),
                content: t('tips.meterName')
            }),
            key: 'meterName'
        },
        {
            title:
                MakePopover({
                    width: 250,
                    title: t('unitOfMeasure'),
                    content: t('tips.unitOfMeasure')
                }),
            key: 'unitOfMeasure'
        },
        {
            title: t('retailPrice'),
            key: 'price'
        },
    ]
}
