import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type Props = {
    lng: string;
}
export const Title = ({ lng }: Props) => {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = t('title.documentTitle');
    }, [lng]);
    return <></>
}