import { useTranslation } from "react-i18next";

export default () => {
    const { t } = useTranslation();
    return [[
        {
            id: "footer_azure",
            title: "",
            links: [
                {
                    href: t('footer.new_footer.a.link'),
                    title: t('footer.new_footer.a.title')
                },
                {
                    href: t('footer.new_footer.b.link'),
                    title: t('footer.new_footer.b.title')
                },
                {
                    href: t('footer.new_footer.c.link'),
                    title: t('footer.new_footer.c.title')
                },
                {
                    href: t('footer.new_footer.d.link'),
                    title: t('footer.new_footer.d.title')
                },
                {
                    href: t('footer.new_footer.e.link'),
                    title: t('footer.new_footer.e.title')
                },
            ]
        }
    ], [
        {
            id: "footer_support",
            title: "",
            links: [
                {
                    href: t('footer.new_footer.f.link'),
                    title: t('footer.new_footer.f.title')
                },
                {
                    href: t('footer.new_footer.g.link'),
                    title: t('footer.new_footer.g.title')
                },
                {
                    href: t('footer.new_footer.h.link'),
                    title: t('footer.new_footer.h.title')
                },
                {
                    href: t('footer.new_footer.i.link'),
                    title: t('footer.new_footer.i.title')
                },
                {
                    href: t('footer.new_footer.j.link'),
                    title: t('footer.new_footer.j.title')
                },
                {
                    href: t('footer.new_footer.k.link'),
                    title: t('footer.new_footer.k.title')
                },
                {
                    href: t('footer.new_footer.l.link'),
                    title: t('footer.new_footer.l.title')
                },
            ]
        }
    ], [
        {
            id: "footer_trusted-center",
            title: "",
            links: [
                {
                    href: t('footer.new_footer.m.link'),
                    title: t('footer.new_footer.m.title')
                },
                {
                    href: t('footer.new_footer.n.link'),
                    title: t('footer.new_footer.n.title')
                },
                {
                    href: t('footer.new_footer.o.link'),
                    title: t('footer.new_footer.o.title')
                },
                {
                    href: t('footer.new_footer.p.link'),
                    title: t('footer.new_footer.p.title')
                },
                {
                    href: t('footer.new_footer.r.link'),
                    title: t('footer.new_footer.r.title')
                },
                {
                    href: t('footer.new_footer.s.link'),
                    title: t('footer.new_footer.s.title')
                },
            ]
        }
    ]]
}