import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Footer, Filter, Last } from './components';

import { Table } from "@fluentliteui/table";
import "@fluentliteui/table/dist/index.css";

import { Space } from "@fluentliteui/space";
import "@fluentliteui/space/dist/index.css";

import { Title } from './components/title';
import { Version } from './components/version';
import { TableHead } from './config/tableHead';

import './App.css';

// media
import './media/index.css';
import { Ospa } from './components/ospa';
import Banner from './components/banner';
// import './media/debug.css';

function App() {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<any[]>([]);

  return (
    <div className="App">
      <Header />
      <Banner />
      <div className='width-wapper'>
        <div style={{ marginTop: "20px" }}>
          <Space style={{ justifyContent: "space-between" }} direction="column">
            <Filter lng={t('lng')} onResultChange={(priceData) => {
              setDataSource(priceData);
            }}><Last lng={t('lng')} />
              <div className='table-wapper'>
                <Table style={{ width: "100%" }} columns={TableHead()} dataSource={dataSource} />
              </div>
            </Filter>
          </Space>
        </div>
      </div>
      <Ospa />
      <Title lng={t('lng')} />
      <Version />
      <Footer />
    </div>
  );
}

export default App;
