import { Option } from "../../types";

export const TreesToOptions = (list: any[], titleKey: string, objKey: string | null, fun: (arr: Option[]) => void) => {
    const arr: Option[] = [];
    list.forEach((item, index) => {
        arr.push({
            key: item[titleKey] + index,
            title: item[titleKey],
            value: item[titleKey],
            objs: objKey ? item[objKey] : ""
        });
    })
    fun(arr);
}

export const StringsToOptions = (list: string[], fun: (arr: Option[]) => void) => {
    const arr: Option[] = [];
    list.forEach((item, index) => {
        arr.push({
            key: item + index,
            title: item,
            value: item,
        });
    })
    fun(arr);
}

export const lng2num = (lng: string) => {
    return lng === "en-us" ? 0 : 1
}