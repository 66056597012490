import { API } from "../../config/api";
import { firstLoadUseGetDefaultPriceListTableDataAPI } from "../../config/firstload";
import { lng2num } from "../converter";

export const QueryRetailPrice = async (lng: string,
    pageNo: any,
    pageSize: any,
    serviceFamily: any,
    serviceName: any,
    productName: any,
    location: any,
    skuName: any,
    type: any) => {
    return new Promise<{ total: number, priceData: any }>(async (resolve, reject) => {
        try {
            if (firstLoadUseGetDefaultPriceListTableDataAPI && serviceFamily === "") {
                const response = await fetch(`${API.GetDefaultPriceListTableData}/${lng2num(lng)}`, {
                    method: "GET", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                });
                const data = await response.json();
                const { total, priceData } = data;
                resolve({ total, priceData });
                return false;
            }

            const response = await fetch(API.QueryRetailPrice, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({
                    language: lng2num(lng),
                    pageNo,
                    pageSize,
                    serviceFamily,
                    serviceName,
                    productName,
                    location,
                    skuName,
                    type
                }), // body data type must match "Content-Type" header
            });
            const data = await response.json();
            const { total, priceData } = data;
            resolve({ total, priceData });

        } catch (error) {
            reject(error);
        }
    })
}