import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@fluentliteui/button";
import "@fluentliteui/button/dist/index.css";
import { Loading } from "@fluentliteui/loading";
import "@fluentliteui/loading/dist/index.css";
import { Space } from "@fluentliteui/space";
import "@fluentliteui/space/dist/index.css";
import { Dropdown } from "@fluentliteui/dropdown";
import "@fluentliteui/dropdown/dist/index.css";
import { Pagenav } from "@fluentliteui/pagenav";
import "@fluentliteui/pagenav/dist/index.css";
import { Dialog } from "@fluentliteui/dialog";
import "@fluentliteui/dialog/dist/index.css";
import AlertIMG from "../icon/alert";
import { Option } from "../../types";

import { QueryRetailPrice } from "../../functions/api";
import {
    getProductNameOptionsForDropdownByServiceName,
    getServiceNameOptionsForDropdownByServiceFamily,
    getSubOptions
} from "../../functions/logic";
import { pageSize } from "../../config/page";
import { onLanguageChange } from "../../functions/logic/event";


type Props = {
    lng: string;
    onResultChange: (data: any) => void;
    children: string | JSX.Element | JSX.Element[] | null
}

export default ({ lng, onResultChange, children }: Props) => {
    // const { t } = useTranslation();
    const { t, i18n } = useTranslation();

    const [requesting, setRequesting] = useState<boolean>(false);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [reload, setReload] = useState<number>(0);

    const [total, setTotal] = useState<number>(0);
    const [pageNo, setPageNo] = useState<number>(1);

    const [ServiceFamilyOptions, setServiceFamilyOptions] = useState<Option[]>([]);
    const [ServiceNameOptions, setServiceNameOptions] = useState<Option[]>([]);
    const [ProductNameOptions, setProductNameOptions] = useState<Option[]>([]);
    const [LocationOptions, setLocationOptionsOptions] = useState<Option[]>([]);
    const [SkuNameOptions, setSkuNameOptions] = useState<Option[]>([]);
    const [TypeOptions, setTypeOptions] = useState<Option[]>([]);

    const [serviceFamily, setServiceFamily] = useState<string>('');
    const [serviceName, setServiceName] = useState<string>('');
    const [productName, setProductName] = useState<string>('');
    const [location, setLocation] = useState<string>('');
    const [skuName, setSkuName] = useState<string>('');
    const [type, setType] = useState<string>('');

    //重新获得数据
    const RePull = () => {
        setReload(reload + 1);
    }

    useEffect(() => {
        setProductNameOptions([]);
        setServiceNameOptions([]);
        setServiceName("");
        setProductName("");
        setLocation("");
        setSkuName("");
        setType("");
        setPageNo(1);
        getServiceNameOptionsForDropdownByServiceFamily(ServiceFamilyOptions, serviceFamily, setServiceNameOptions);
    }, [serviceFamily]);

    useEffect(() => {
        setLocation("");
        setSkuName("");
        setType("");
        getProductNameOptionsForDropdownByServiceName(ServiceNameOptions, serviceName, setProductNameOptions);
        setProductName("");
    }, [serviceName]);

    useEffect(() => {
        setLocation("");
        setSkuName("");
        setType("");

        setLocationOptionsOptions([]);
        setSkuNameOptions([]);
        setTypeOptions([]);

        getSubOptions(lng, serviceFamily, serviceName, productName,
            setLocationOptionsOptions,
            setSkuNameOptions,
            setTypeOptions
        )
    }, [productName]);

    const getResult = async () => {
        setRequesting(true);
        QueryRetailPrice(
            lng,
            pageNo,
            pageSize,
            serviceFamily,
            serviceName,
            productName,
            location,
            skuName,
            type
        ).then(({ total, priceData }) => {
            if (total === 0) {
                setShowDialog(true);
            }
            setTotal(total);
            onResultChange(priceData);
            setRequesting(false);
        }).catch(() => {
            setRequesting(false);
        });
    }

    const resetparams = () => {
        setServiceFamily("");
        setServiceName("");
        setProductName("");
        setLocation("");
        setSkuName("");
        setType("");

        setPageNo(1);
    }

    const resetOptions = () => {
        setServiceFamilyOptions([]);
        setServiceNameOptions([]);
        setProductNameOptions([]);
        setLocationOptionsOptions([]);
        setSkuNameOptions([]);
        setTypeOptions([]);
    }

    const reset = () => {
        resetOptions();
        resetparams();
        onResultChange([]);
    }

    useEffect(() => {
        onLanguageChange(
            reset,
            lng, setServiceFamilyOptions,
            setServiceFamily,
            setLocation,
            setType,
            RePull
        );
    }, [lng])

    useEffect(() => {
        if (reload > 0) {
            getResult();
        }
    }, [reload]);

    return <div>
        <div dangerouslySetInnerHTML={{ __html: t('filter.html') }}></div>
        <Dialog show={showDialog} body={<div style={{ width: "300px", display: "flex", alignItems: "start", padding: "15px" }}>
            <AlertIMG />
            <p style={{ margin: "0 0 0 10px" }}>{t('totalZero')}</p></div>} footer={<Button style={{ marginTop: "10px", width: "80px", marginRight: "15px", marginBottom: "15px" }} variant="primary" onClick={() => {
                setShowDialog(false);
            }} >{t('ok')}</Button>} />
        <Space className='filter' direction="column">
            <div className="pc">
                {[<Space gap={20} justify="space-between">
                    <Dropdown disabled={ServiceFamilyOptions.length === 0} className="dropdownItem" onOptionChange={setServiceFamily} label={t('serviceFamily')} value={serviceFamily} options={ServiceFamilyOptions} />
                    <Dropdown disabled={ServiceNameOptions.length === 0} className="dropdownItem" onOptionChange={setServiceName} label={t('serviceName')} value={serviceName} options={ServiceNameOptions} />
                    <Dropdown disabled={ProductNameOptions.length === 0} className="dropdownItem" onOptionChange={setProductName} label={t('productName')} value={productName} options={ProductNameOptions} />
                </Space>, <Space gap={20} justify="space-between">
                    <Dropdown disabled={LocationOptions.length === 0} className="dropdownItem" onOptionChange={setLocation} label={t('location')} value={location} options={LocationOptions} />
                    <Dropdown disabled={SkuNameOptions.length === 0} className="dropdownItem" onOptionChange={setSkuName} label={t('skuName')} value={skuName} options={SkuNameOptions} />
                    <Dropdown disabled={TypeOptions.length === 0} className="dropdownItem" onOptionChange={setType} label={t('type')} value={type} options={TypeOptions} />
                </Space>].map(jsx => {
                    return <div style={{ marginTop: "10px" }}>
                        {jsx}
                    </div>
                })}
            </div>
            <div className="pad">
                {
                    [<Space gap={20} justify="space-between">
                        <Dropdown disabled={ServiceFamilyOptions.length === 0} className="dropdownItem" onOptionChange={setServiceFamily} label={t('serviceFamily')} value={serviceFamily} options={ServiceFamilyOptions} />
                        <Dropdown disabled={ServiceNameOptions.length === 0} className="dropdownItem" onOptionChange={setServiceName} label={t('serviceName')} value={serviceName} options={ServiceNameOptions} />
                    </Space>, <Space gap={20} justify="space-between">
                        <Dropdown disabled={ProductNameOptions.length === 0} className="dropdownItem" onOptionChange={setProductName} label={t('productName')} value={productName} options={ProductNameOptions} />
                        <Dropdown disabled={LocationOptions.length === 0} className="dropdownItem" onOptionChange={setLocation} label={t('location')} value={location} options={LocationOptions} />
                    </Space>, <Space gap={20} justify="space-between">
                        <Dropdown disabled={SkuNameOptions.length === 0} className="dropdownItem" onOptionChange={setSkuName} label={t('skuName')} value={skuName} options={SkuNameOptions} />
                        <Dropdown disabled={TypeOptions.length === 0} className="dropdownItem" onOptionChange={setType} label={t('type')} value={type} options={TypeOptions} />
                    </Space>].map(jsx => {
                        return <div style={{ marginTop: "10px" }}>
                            {jsx}
                        </div>
                    })
                }
            </div>
            <div className="mob">
                <div style={{ marginTop: "10px" }}>
                    <Space gap={20} justify="space-between" direction="column">
                        <Dropdown disabled={ServiceFamilyOptions.length === 0} className="dropdownItem" onOptionChange={setServiceFamily} label={t('serviceFamily')} value={serviceFamily} options={ServiceFamilyOptions} />
                        <Dropdown disabled={ServiceNameOptions.length === 0} className="dropdownItem" onOptionChange={setServiceName} label={t('serviceName')} value={serviceName} options={ServiceNameOptions} />
                        <Dropdown disabled={ProductNameOptions.length === 0} className="dropdownItem" onOptionChange={setProductName} label={t('productName')} value={productName} options={ProductNameOptions} />
                        <Dropdown disabled={LocationOptions.length === 0} className="dropdownItem" onOptionChange={setLocation} label={t('location')} value={location} options={LocationOptions} />
                        <Dropdown disabled={SkuNameOptions.length === 0} className="dropdownItem" onOptionChange={setSkuName} label={t('skuName')} value={skuName} options={SkuNameOptions} />
                        <Dropdown disabled={TypeOptions.length === 0} className="dropdownItem" onOptionChange={setType} label={t('type')} value={type} options={TypeOptions} />
                    </Space>
                </div>
            </div>

            <div style={{ width: "100%", marginTop: "10px" }}></div>
            <Space gap={10} justify="end">
                <Loading loading={requesting}><Button onClick={() => {
                    RePull();
                }} variant="primary">{t('search')}</Button></Loading>
                <Button onClick={() => {
                    setServiceFamily("");
                    setServiceName("");
                    setProductName("");
                    setLocation("");
                    setSkuName("");
                    setType("");

                    setServiceNameOptions([]);
                    setProductNameOptions([]);
                    setLocationOptionsOptions([]);
                    setSkuNameOptions([]);
                    setTypeOptions([]);

                    onResultChange([]);
                }}>{t('reset')}</Button>
            </Space>
        </Space>
        <Loading style={{ background: "#f00", marginTop: "20px" }} loading={requesting}>
            {children}
        </Loading>
        <div style={{ width: "100%", marginTop: "20px" }}></div>
        <div className="page-nav">
            <Space gap={0} justify="end">
                {
                    Math.ceil(total / pageSize) > 1 && <Pagenav onPageChange={(x) => {
                        setPageNo(x);
                        RePull();
                    }} max={Math.ceil(total / pageSize)} active={pageNo} />
                }
            </Space>
        </div>
    </div>
}