import { Details } from "@fluentliteui/details";
import "@fluentliteui/details/dist/index.css";

const Item = (item: { id: string; title: string; links: any[] }) => {
    return <div className="item">
        <h3 id={item.id} className="menu-title unmob"><span>{item.title}</span><span className="icon-arrow-down"></span></h3>
        <ul className="menu-child unmob">
            {
                item.links.map(link => {
                    return <Li {...link} />
                })
            }
        </ul>
        <Details extra={<span className="icon-arrow-down"></span>} label={item.title}>
            <ul className="details-child">
                {
                    item.links.map(link => {
                        return <Li {...link} />
                    })
                }
            </ul>
        </Details>
    </div>
}

const Li = (link: { id: string; href: string; title: string }) => {
    return <li>
        <a id={link.id} href={link.href} title={link.title} target="_blank">
            {link.title}
        </a>
    </li>
}

const Links = ({ li }: any) => {
    return <li>
        {
            li.map((item: any) => {
                return <Item {...item} />
            })
        }
    </li>
}

const FooterLinks = ({ links }: any) => {
    return <>
        {
            links.map((li: any[]) => {
                return <Links li={li} />
            })
        }
    </>
}

export {
    FooterLinks
}