import { useEffect, useState } from "react"

export const Version = () => {
    const [show, setShow] = useState<boolean>(false);
    useEffect(() => {
        document.location.href.includes("version") ? setShow(true) : setShow(false);
    }, []);
    return <div className="width-wapper Version">
        {
            show && <div>
                <p><b>Env</b>:{process.env.REACT_APP_ENV}</p>
                <p><b>Last</b>:{process.env.REACT_APP_LASTUPDATE}</p>
            </div>
        }
    </div>
}