const zh = {
    htmlbak:`<p>新商务模式（21VCA-E）以高效灵活的创新模式，满足您的企业在数字化转型中的新需求，助力企业提韧增效，加速商业成功。新商务模式引入了新的优惠和条款，使您在购买方式和时间方面有更大的选择和灵活性。
    <a target="_blank" href="https://www.21vbluecloud.com/notice/customer-announcement-new-commerce-experience-on-microsoft-azure-operated-by-21vianet-commercial-ga-on-july-13-2023/">点击这里</a>
    了解关于21VCA-E的更多详情。</p>
    <p><b>适用于计算的Azure节省计划：</b>适用于计算的Azure节省计划：<a target="_blank" href="https://docs.azure.cn/zh-cn/cost-management-billing/savings-plan/savings-plan-compute-overview">点击这里</a>了解更多。</p>
    <p><b>Azure预留：</b>Azure 预留项通过承诺多种产品的一年期或三年期计划，帮助您节省资金。<a target="_blank" href="https://docs.azure.cn/zh-cn/cost-management-billing/reservations/save-compute-costs-reservations">点击这里</a>了解更多。</p>
    <p><b>Azure上的开发和测试：</b>提升团队能力，使其能够快速预配开发/测试和预生产环境，从而交付高质量的产品、应用程序和服务。点击这里了解更多。<a target="_blank" href="https://azure.microsoft.com/zh-cn/solutions/dev-test/">点击这里</a>了解更多。</p>
    <p>　</p>
    <p><b>查找适用于计算的Azure节省计划、Azure预留和Azure上的开发和测试价格：</b></p>`,
    html:`<p>新商务模式（21VCA-E）以高效灵活的创新模式，满足您的企业在数字化转型中的新需求，助力企业提韧增效，加速商业成功。新商务模式引入了新的优惠和条款，使您在购买方式和时间方面有更大的选择和灵活性。
    <a target="_blank" href="https://www.21vbluecloud.com/notice/customer-announcement-new-commerce-experience-on-microsoft-azure-operated-by-21vianet-commercial-ga-on-july-13-2023/">点击这里</a>
    了解关于21VCA-E的更多详情。</p>
    <p><b>适用于计算的Azure节省计划：</b>适用于计算的Azure节省计划：<a target="_blank" href="https://docs.azure.cn/zh-cn/cost-management-billing/savings-plan/savings-plan-compute-overview">点击这里</a>了解更多。</p>
    <p><b>Azure预留：</b>Azure 预留项通过承诺多种产品的一年期或三年期计划，帮助您节省资金。<a target="_blank" href="https://docs.azure.cn/zh-cn/cost-management-billing/reservations/save-compute-costs-reservations">点击这里</a>了解更多。</p>
    <p>　</p>
    `
}
const en = {
    htmlbak:`<p>The 21VCA-E supports your company’s digital transformation with an efficient and flexible innovative model, and help you improve resilience, increase efficiency, and accelerate business growth. The new offers and terms provide you with greater choice and flexibility in how and when to buy. Please <a target="_blank" href="https://en.21vbluecloud.com/new-commerce-experience-on-microsoft-azure-operated-by-21vianet-commercial-ga-on-july-13-2023.html">Click HERE</a> to know more about the 21VAC-E.</p>
    <p><b>Azure Savings Plan for compute introduction: </b>Azure savings plan for compute is a flexible pricing model. It provides savings up to 65% off pay-as-you-go pricing when you commit to spend a fixed hourly amount on compute services for one or three years. <a target="_blank" href="https://docs.azure.cn/en-us/cost-management-billing/savings-plan/savings-plan-compute-overview">Click HERE</a> to know more.</p>
    <p><b>Azure reservation introduction: </b>Azure Reservations help you save money by committing to one-year or three-year plans for multiple products. <a target="_blank" href="https://docs.azure.cn/en-us/cost-management-billing/reservations/save-compute-costs-reservations">Click HERE</a> to know more.</p>
    <p>Develop and test on Azure:&nbsp;Empower your team to quickly provision dev/test and pre-production environments to deliver quality products, applications, and services. <a target="_blank" href="https://azure.microsoft.com/en-us/solutions/dev-test/">Click HERE</a> to know more.</p>
    <p>　</p>
    <p><b>Find Azure savings plan for compute, Azure reservation, and Develop and test on Azure pricing: </b></p>`,
    html:`<p>The 21VCA-E supports your company’s digital transformation with an efficient and flexible innovative model, and help you improve resilience, increase efficiency, and accelerate business growth. The new offers and terms provide you with greater choice and flexibility in how and when to buy. Please <a target="_blank" href="https://en.21vbluecloud.com/new-commerce-experience-on-microsoft-azure-operated-by-21vianet-commercial-ga-on-july-13-2023.html">Click HERE</a> to know more about the 21VAC-E.</p>
    <p><b>Azure Savings Plan for compute introduction: </b>Azure savings plan for compute is a flexible pricing model. It provides savings up to 65% off pay-as-you-go pricing when you commit to spend a fixed hourly amount on compute services for one or three years. <a target="_blank" href="https://docs.azure.cn/en-us/cost-management-billing/savings-plan/savings-plan-compute-overview">Click HERE</a> to know more.</p>
    <p><b>Azure reservation introduction: </b>Azure Reservations help you save money by committing to one-year or three-year plans for multiple products. <a target="_blank" href="https://docs.azure.cn/en-us/cost-management-billing/reservations/save-compute-costs-reservations">Click HERE</a> to know more.</p>
    <p>　</p>
    `
}
export {
    en, zh
}