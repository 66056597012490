import { API } from "../../config/api";

export const GetProductLevelFilterResources = async (lng: string) => {
    return new Promise<any[]>(async (resolve, reject) => {
        try {
            const response = await fetch(`${API.GetProductLevelFilterResources}/${lng === 'en-us' ? 0 : 1}`);
            const jsonData = await response.json();
            const { serviceFamily } = jsonData;
            resolve(serviceFamily);
        } catch (error) {
            reject(error);
        }
    })
}