const zh = {
    whyAzure: "认识 Azure",
    products: "产品和定价",
    solutions: "解决方案",
    partners: "案例与伙伴",
    documentation: "文档",
    blog: "博客",
    training: "培训",
    support: "支持",
    marketplace: "Azure 市场",
    global: "Azure Global/China",
    azurePortal: "登录 Azure 帐户",
    apply: "联系销售人员",
    menu: {
        a: {
            title: "产品和定价",
            link: "https://www.azure.cn/pricing/"
        },
        b: {
            title: "Azure 文档 >",
            link: "https://docs.azure.cn/zh-cn/?product=popular"
        },
        c: {
            title: "Azure 市场 >",
            link: "https://market.azure.cn/"
        },
        d: {
            title: "Azure 支持计划 >",
            link: "https://support.azure.cn/zh-cn/support/plans/"
        }
    }
}
const en = {
    whyAzure: "Why Azure",
    products: "Products",
    pricing: "Pricing",
    solutions: "Solutions",
    partners: "Partners",
    documentation: "Documentation",
    blog: "Blog",
    training: "Training",
    support: "Support",
    marketplace: "Marketplace",
    global: "Azure Global/China",
    azurePortal: "Sign in to your Azure account",
    apply: "Contact Sales",
    menu: {
        a: {
            title: "Products and Pricing",
            link: "https://www.azure.cn/en-us/pricing/"
        },
        b: {
            title: "Documentation >",
            link: "https://docs.azure.cn/en-us/?product=popular"
        },
        c: {
            title: "Azure Marketplace >",
            link: "https://market.azure.cn/"
        },
        d: {
            title: "Support Plans >",
            link: "https://support.azure.cn/en-us/support/plans/"
        }
    }
}
export {
    en, zh
}