import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { API } from "../../config/api";
import "./style.css";
type Props = {
    lng: string;
}
export default ({ lng }: Props) => {
    const { t, i18n } = useTranslation();
    const [date, setDate] = useState<string>("");
    const [src, setSrc] = useState<string>("");
    const get = async () => {
        const response = await fetch(`${API.GetLatestPriceListFile}/${lng === 'en-us' ? 0 : 1}`);
        const { fileName, fileUri, createdDate } = await response.json();
        // console.log({ fileUri, createdDate })
        setDate(createdDate);

        setSrc(fileUri)
    }
    useEffect(() => {
        get();
    }, [lng])
    return <div className="last">
        <span>{t('update')}：{moment(date).format(t('dateFormat'))}</span><a href={src}>{t('exportToExcelFormatAndDownload')}</a>
    </div>
}