import { useTranslation } from "react-i18next";
export default () => {
    const { t } = useTranslation();
    return [
        {
            href: t('header.menu.a.link'),
            title: t('header.menu.a.title')
        },
        {
            href: t('header.menu.b.link'),
            title: t('header.menu.b.title')
        },
        {
            href: t('header.menu.c.link'),
            title: t('header.menu.c.title')
        },
        {
            href: t('header.menu.d.link'),
            title: t('header.menu.d.title')
        }
    ]
}