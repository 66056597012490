import { Popover } from "@fluentliteui/popover";
import "@fluentliteui/popover/dist/index.css";

type Props = {
    isHtml?: boolean;
    width: number;
    title: string;
    content: string;
}

export const MakePopover = (props: Props) => {
    const { isHtml = false, width, title, content } = props;
    return <>
        {isHtml ? <Popover pop={<div className='pop-body-wapper' dangerouslySetInnerHTML={{ __html: content }} style={{ width: `${width}px` }} />}>{title}</Popover> : <Popover pop={<div className='pop-body-wapper' style={{ width: `${width}px` }}>{content}</div>}>{title}</Popover>}
    </>
}