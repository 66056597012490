export const getDefaultValueForDropdown = async (lng: string) => {

    return new Promise<any>((resolve, reject) => {
        try {
            console.log();

            if (lng === "en-us") {
                resolve({
                    ServiceFamily: "Compute",
                    Location: "CN North 3",
                    Type: "Reservation"
                });
            } else {
                resolve({
                    ServiceFamily: "计算",
                    Location: "中国北部 3",
                    Type: "Reservation"
                });
            }

        } catch (error) {
            reject(error);
        }
    })
}