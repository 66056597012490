import { useTranslation } from "react-i18next";
import getNav from '../../data/header';

import { Details } from "@fluentliteui/details";
import "@fluentliteui/details/dist/index.css";

import "./style.css";

const Li = (li: any) => {
    return <li>
        <a className="title" id={li.id} target="_blank" href={li.href} title={li.title}>
            {li.title}
        </a>
    </li>
}

const Nav = (props: any) => {
    return <ul className={props.className}>
        {
            getNav().map(li => {
                return <Li {...li} />
            })
        }{props.children}</ul>
}

export default () => {
    const { t, i18n } = useTranslation();
    return <div style={{ zIndex: 9 }} className={`header`}>
        <div className="width-wapper pc pad">
            <div className="logo-and-links">
                <div className={`logo ${t('lng')}`}></div>
                <Nav className="links" />
            </div>
            <div className="apply-and-portal">
                <a className="portal-link" href="https://portal.azure.cn/" title={t('header.azurePortal')} target="_blank">
                    {t('header.azurePortal')}
                </a>
                {/* <a className="buy-it" href="https://www.microsoft.com/china/azure/index.html" title={t('header.apply')} target="_blank">
                    {t('header.apply')}
                </a> */}
            </div>
        </div>

        <Details className="width-wapper mob" extra={<div className="mobile-menu icon-menu"></div>}
            label={<div className="logo-and-links"><div className={`logo ${t('lng')}`}></div></div>}>
            <Nav className="header-menus">
                <li>
                    <a className="portal-link" href="https://portal.azure.cn/" title={t('header.azurePortal')} target="_blank">
                        {t('header.azurePortal')}
                    </a>
                </li>
                <li>
                    <a className="buy-it" href="https://www.microsoft.com/china/azure/index.html" title={t('header.apply')} target="_blank">
                        {t('header.apply')}
                    </a>
                </li>
            </Nav>
        </Details>
    </div>
}