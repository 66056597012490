import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as header from './header';
import * as footer from './footer';
import * as tips from './tips';
import * as title from './title';
import * as filter from './filter';
import * as banner from './banner';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            banner: banner.en,
            title: title.en,
            ok: "OK",
            search: "Search",
            reset: "Reset",
            serviceFamily: "Service Family",
            serviceName: "Service Name",
            productName: "Product Name",
            location: "Location",
            skuName: "SKU",
            type: "Type",
            meterName: "Meter Name",
            term: "Term",
            unitOfMeasure: "Unit Of Measure",
            retailPrice: "Retail Price (RMB, VAT excluded)",
            lng: 'en-us',
            ospa: {
                title: 'See pricing for Online Service Premium Agreement Azure ',
                click: 'Click Here'
            },
            filter: filter.en,
            header: header.en,
            footer: footer.en,
            update: "Last Update",
            exportToExcelFormatAndDownload: "Export and download (.xlsx)",
            tips: tips.en,
            dateFormat: "YYYY/MM/DD",
            totalZero: "No search results found,  please change your search criteria and try again. "
        }
    },
    zh: {
        translation: {
            banner: banner.zh,
            title: title.zh,
            ok: "确定",
            search: "筛选",
            reset: "重置",
            serviceFamily: "服务系列",
            serviceName: "服务名称",
            productName: "产品名称",
            location: "区域",
            skuName: "SKU",
            type: "种类",
            meterName: "计量名称",
            term: "期限",
            unitOfMeasure: "度量单位",
            retailPrice: "零售价格 (RMB, 不含VAT)",
            lng: 'zh-cn',
            ospa: {
                title: '世纪互联在线服务高级协议Azure价格',
                click: '请点击'
            },
            filter: filter.zh,
            header: header.zh,
            footer: footer.zh,
            update: "最近更新日期",
            exportToExcelFormatAndDownload: "导出 Excel 表格并下载",
            tips: tips.zh,
            dateFormat: "YYYY年MM月DD日",
            totalZero: "您选择的产品类型组合不匹配，请重新调整过滤条件"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: document.location.href.includes("en-us") ? 'en-us' : 'zh-ch', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;