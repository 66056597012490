import { Option } from "../../types";
import { GetProductLevelFilterResources, GetSubLevelFilterResources } from "../api";
import { StringsToOptions, TreesToOptions } from "../converter";

export const getServiceFamilyOptionsForDropdown = (
    lng: string,
    setServiceFamilyOptions: (options: Option[]) => void,
    callback: () => void
) => {
    GetProductLevelFilterResources(lng).then((serviceFamily) => {
        TreesToOptions(serviceFamily, "serviceFamilyLabel", "serviceName", setServiceFamilyOptions);
        callback();
    }).catch((err) => console.log(err));
}

export const getServiceNameOptionsForDropdownByServiceFamily = (
    options: Option[],
    serviceFamily: string,
    setServiceNameOptions: (options: Option[]) => void
) => {
    const res = options.filter(opt => opt.value === serviceFamily);
    if (res.length > 0) {
        TreesToOptions(res[0].objs || [], "serviceNameLabel", "productName", setServiceNameOptions);
    }
}

export const getProductNameOptionsForDropdownByServiceName = (
    options: Option[],
    serviceName: string,
    setProductNameOptions: (options: Option[]) => void
) => {
    const res = options.filter(opt => opt.value === serviceName);
    if (res.length > 0) {
        TreesToOptions(res[0].objs || [], "productNameLabel", null, setProductNameOptions);
    }
}

export const getSubOptions = (
    lng: string, serviceFamily: string, serviceName: string, productName: string,
    setLocationOptionsOptions: (options: Option[]) => void,
    setSkuNameOptions: (options: Option[]) => void,
    setTypeOptions: (options: Option[]) => void
) => {
    if (productName != "") {
        GetSubLevelFilterResources(lng, serviceFamily, serviceName, productName).then((data) => {
            const { location, skuName, type } = data;
            StringsToOptions(location, setLocationOptionsOptions);
            StringsToOptions(skuName, setSkuNameOptions);
            StringsToOptions(type, setTypeOptions);
        }).catch(error => console.log(error));
    }
}