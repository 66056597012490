const zh = {
    getQuickSupport: "快速获取支持",
    followus: "关注我们",
    solutions: "通用解决方案",
    documentation: "Azure 文档 >",
    offerDetails: "优惠详情",
    azureinChinaPurchaseGuidance: "Azure 混合权益（Azure Hybrid Benefit）",
    visitGlobalAzure: "访问全球版 Azure 网站",
    icp: "沪ICP备13015306号-25",
    psb: "沪公网安备 31011502002224号",
    privacy: "隐私",
    videoCenter: "视频中心",
    caseStudies: "客户案例",
    new_footer: {
        a: {
            title: "产品和定价",
            link: "https://www.azure.cn/pricing/"
        },
        b: {
            title: "Azure 混合权益（Azure Hybrid Benefit）",
            link: "https://www.azure.cn/pricing/hybrid-benefit/"
        },
        c: {
            title: "Azure 文档 >",
            link: "https://docs.azure.cn/zh-cn/?product=popular"
        },
        d: {
            title: "获取支持 >",
            link: "https://support.azure.cn/zh-cn/support/contact/"
        },
        e: {
            title: "Azure 支持计划 >",
            link: "https://support.azure.cn/zh-cn/support/plans/"
        },
        f: {
            title: "Azure 门户 >",
            link: "https://portal.azure.cn/"
        },
        g: {
            title: "企业门户网站 >",
            link: "https://ea.azure.cn/"
        },
        h: {
            title: "帐户中心 >",
            link: "https://account.windowsazure.cn/Subscriptions"
        },
        i: {
            title: "Microsoft Azure >",
            link: "https://azure.microsoft.com/zh-cn/"
        },
        j: {
            title: "可用产品(按区域) >",
            link: "https://azure.microsoft.com/zh-cn/explore/global-infrastructure/products-by-region/"
        },
        k: {
            title: "Azure 状态 >",
            link: "https://azure.status.microsoft/zh-cn/status"
        },
        l: {
            title: "Azure 地域 >",
            link: "https://datacenters.microsoft.com/"
        },
        m: {
            title: "服务级别协议 (SLA)",
            link: "https://www.azure.cn/support/legal/sla/"
        },
        n: {
            title: "法律信息",
            link: "https://www.azure.cn/support/legal/"
        },
        o: {
            title: "ICP 备案",
            link: "https://www.azure.cn/support/icp/"
        },
        p: {
            title: "公安备案",
            link: "https://www.azure.cn/support/announcement/public-security-registration/"
        },
        // q: {
        //     title: "申请中国税务发票 >",
        //     link: "https://docs.azure.cn/zh-cn/billing/billing-get-invoice"
        // },
        r: {
            title: "在线服务隐私声明 >",
            link: "https://www.21vbluecloud.com/ostpt/"
        }, s: {
            title: "信任中心 >",
            link: "https://www.trustcenter.cn/"
        }
    }
}
const en = {
    getQuickSupport: "Get support fast",
    followus: "Follow us",
    solutions: "Solutions",
    documentation: "Documentation",
    offerDetails: "Offer Details",
    azureinChinaPurchaseGuidance: "Azure in China Purchase Guidance",
    visitGlobalAzure: "Visit Global Azure",
    icp: "SH ICP Filing No. 13015306-25",
    psb: "PSB Filing No. 31011502002224",
    privacy: "Privacy",
    videoCenter: "Video Center",
    caseStudies: "Case Studies",
    new_footer: {
        a: {
            title: "Products and pricing",
            link: "https://www.azure.cn/en-us/pricing/"
        },
        b: {
            title: "Azure Hybrid Benefit",
            link: "https://www.azure.cn/en-us/pricing/hybrid-benefit/"
        },
        c: {
            title: "Azure Documentation >",
            link: "https://docs.azure.cn/en-us/?product=popular"
        },
        d: {
            title: "Get support >",
            link: "https://support.azure.cn/en-us/support/contact/"
        },
        e: {
            title: "Azure support plans >",
            link: "https://support.azure.cn/en-us/support/plans/"
        },
        f: {
            title: "Azure portal >",
            link: "https://portal.azure.cn/"
        },
        g: {
            title: "Enterprise portal >",
            link: "https://ea.azure.cn/"
        },
        h: {
            title: "Account center >",
            link: "https://account.windowsazure.cn/Subscriptions"
        },
        i: {
            title: "Microsoft Azure >",
            link: "https://azure.microsoft.com/en-gb/"
        },
        j: {
            title: "Products available by region >",
            link: "https://azure.microsoft.com/en-gb/explore/global-infrastructure/products-by-region/"
        },
        k: {
            title: "Azure status >",
            link: "https://azure.status.microsoft/en-us/status"
        },
        l: {
            title: "Azure geographies >",
            link: "https://datacenters.microsoft.com/"
        },
        m: {
            title: "Service-level agreement (SLA)",
            link: "https://www.azure.cn/en-us/support/legal/sla/"
        },
        n: {
            title: "Legal Information",
            link: "https://www.azure.cn/en-us/support/legal/"
        },
        o: {
            title: "ICP filing",
            link: "https://www.azure.cn/support/icp/"
        },
        p: {
            title: "Public security filing",
            link: "https://www.azure.cn/support/announcement/public-security-registration/"
        },
        q: {
            title: "Apply for a Chinese tax invoice >",
            link: "https://docs.azure.cn/en-us/billing/billing-get-invoice"
        },
        r: {
            title: "Privacy statement >",
            link: "https://www.21vbluecloud.com/ostpt/"
        },
        s: {
            title: "Trust Center >",
            link: "https://www.trustcenter.cn/en-us/"
        }
    }
}
export {
    en, zh
}