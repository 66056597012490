import { useTranslation } from "react-i18next";
import getLinks from '../../data/footer';
import { FooterLinks } from "./links";
import "./style.css";

export default () => {
    const { t, i18n } = useTranslation();
    return <footer className="footer" id="footer" style={{ marginTop: "20px" }}>
        <div className="width-wapper">
            <div className="footer-navigation">
                <li className="follow-us">
                    <div className="item">
                        <h3 className="title">{t('footer.followus')}</h3>
                        <div className="qrcode-wrapper">
                            <div className="qrcode qrcode-2"></div>
                            <span>{t('footer.followus')}</span>
                        </div>
                    </div>
                    <div className="item">
                        <h3 className="title">{t('footer.getQuickSupport')}</h3>
                        <div className="qrcode-wrapper">
                            <div className="qrcode qrcode-3"></div>
                            <span>{t('footer.getQuickSupport')}</span>
                        </div>
                    </div>

                </li>
                <FooterLinks links={getLinks()} />
            </div>
            <div className="footer-help">
                <a className="pointer" onClick={() => { i18n.changeLanguage('zh'); }} id="footer-overseas" rel="nofollow" target="_blank">简体中文</a>
                <a className="pointer" onClick={() => { i18n.changeLanguage('en'); }} id="footer-overseas" rel="nofollow" target="_blank">English</a>

                <a className="" id="footer-overseas" href="https://azure.microsoft.com/" title={t('footer.visitGlobalAzure')} rel="nofollow" target="_blank">{t('footer.visitGlobalAzure')}</a>
                <a className="" id="footer-icp-link" href="https://beian.miit.gov.cn/" title={t('footer.icp')} rel="nofollow" target="_blank">{t('footer.icp')}</a>
                <a className="" id="footer-government-link" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502002224" title="沪公网安备 31011502002224号" rel="nofollow" target="_blank">
                    <img alt={t('footer.psb')} src="/images/png/icpicon.png" className="vertical-icon" />{t('footer.psb')}
                </a>
                <a className="" id="footer-privacy-link" href="https://www.azure.cn/support/legal/privacy-statement/" title={t('footer.privacy')}>{t('footer.privacy')}</a>
            </div>
        </div>
    </footer >
}