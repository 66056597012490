import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

export default () => {
    // const { t } = useTranslation();
    const { t, i18n } = useTranslation();

    return <div className="banner">
        <div className='width-wapper'>
            <h1>{t('banner.title')}</h1>
            <p>{t('banner.subtitle')}</p>
            <p>
                {/* <i className="icon icon-tick" /> */}
                {t('banner.p1')}
                {t('banner.p2')}
                {t('banner.p3')}
            </p>
        </div>
    </div>
}